:root {
  --menu-items-height: 24px;
  --active-bar-width: 3px;
  --icon-left-padding: 18px;
}

.sider {
  position: fixed !important;
  border-right: 0;
  z-index: 100;
  background: var(--secondary-color-nb-900) !important;
}

.sider :global(.ant-layout-sider-children) {
  height: 100vh;
  background: var(--secondary-color-nb-900) !important;
  border-right: 1px solid var(--secondary-color-nb-800) !important;
}

.sider :global(.ant-menu) {
  background: var(--secondary-color-nb-900) !important;
}

.expandSider {
  position: absolute;
  top: 0;
  left: 51px;
  width: 137px;
  height: 100%;
  z-index: 1000;
  background: var(--secondary-color-nb-900) !important;
  border-right: 1px solid var(--chart-background);
}

.menuItem {
  text-overflow: clip;
  overflow: visible;
}

.menuTitle {
  color: var(--secondary-color-nb-300);
  cursor: pointer;
  height: var(--menu-items-height);
  display: flex;
  align-items: center;
}

.navigationIcon {
  display: flex;
  flex-direction: row;
  height: var(--menu-items-height);
  align-items: center;
  border-left: var(--active-bar-width) solid transparent;
  padding-left: calc(var(--icon-left-padding) - var(--active-bar-width));
}

.activeNavigationIcon {
  border-left: var(--active-bar-width) solid var(--secondary-color-tq-400);
  color: var(--secondary-color-tq-600) !important;
}

.navLink {
  display: block;
  color: var(--secondary-color-nb-300);
  margin-top: 24px;
  height: 24px;
  line-height: 24px;
}

.navLinkSettings {
  margin-top: 66px;
}

.navLink:hover {
  color: var(--secondary-color-nb-300);
}

.activeNavLink {
  color: var(--secondary-color-nb-0);
}

.activeNavLink:hover {
  color: var(--secondary-color-nb-300);
}

.iconActiveColor {
  color: var(--secondary-color-tq-500) !important;
}
