.orange-title-center {
  color: #f16e00;
  text-align: center;
  padding: 1.1vw;
}

.orange-title {
  color: #f16e00;
}

.circle-wrapper {
  padding: 2%;
}

.circle {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #f16e00;
}

.circle-metric-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.8vw;
  width: 100%;
  min-height: 7vw;
}

.circle-metric-wrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
}

.circle-metric-wrapper > div > div > div > div {
  width: 100%;
  height: 100%;
}

.circle-metric-wrapper > div > div > div > div > div {
  min-width: unset;
  margin-left: 1%;
  max-width: 80%;
}

.circle-metric-wrapper h2 {
  color: #fff !important;
  padding-top: 0.3vw;
  margin: 0;
  line-height: unset;
  font-weight: bold;
}

.circle-metric-wrapper > div > div > div > div > div span {
  font-weight: bold;
  margin-top: 2%;
  font-size: 0.8vw;
  line-height: 0.8vw;
}

.circle-metric-wrapper br {
  display: none;
}

.circle-metric-wrapper > div > div > div > div > div:nth-of-type(2) h2 {
  font-size: 0.7vw;
  line-height: 0.8vw;
  font-weight: normal;
  margin-top: -24px;
  max-width: 80%;
}

.inside-donut h2 {
  padding-top: 0.8vw;
}

.inside-donut > div > div > div > div > div span {
  margin-top: 0;
  font-size: 0.65vw;
  width: 75%;
}

.card-logo {
  width: 8vw;
}
