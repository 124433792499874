.visualCard {
  min-height: 120px;
}

.visualCard > div {
  padding: 8px;
}

.applyWrapper {
  text-align: right;
}
