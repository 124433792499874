body .content {
  overflow: auto;
}

.sideNavigation {
  margin-left: var(--side-navigation-width);
}

.contentWrapper {
  margin-top: 64px;
}
