.filter {
  margin-bottom: 30px;
}

.filterLabel {
  padding-top: 7px;
  text-align: right;
}

.filterSelect {
  width: 95%;
}

.filterClickable {
  margin-bottom: 14px;
}

.circleMetricWrapper {
  text-align: center;
}

.circleMetricWrapper > div {
  display: inline-block;
}

.circleMetricWrapper > div > div {
  position: relative;
  min-height: 230px;
}

.circleMetricWrapper > div > div > div {
  padding-top: 70px;
  width: 220px;
}

.circleMetricWrapper svg {
  position: absolute;
  top: -10px;
  left: 0px;
}

.barchartStackedLegendTooltip {
  position: absolute;
  left: 50px;
  top: 20;
  background-color: rgba(0, 0, 0, 0.85);
  border: 1px solid #595959;
  min-width: 100px;
  border-radius: 6px;
  padding: 10px;
}
