.btn {
  background: var(--primary-color);
  border-radius: var(--btn-border-radius-base);
  border: 0;
  color: var(--white-color);
  cursor: pointer;
  font-size: var(--font-size-regular);
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  transition: 0.3s;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  outline: none;
}

.btn svg {
  width: 16px;
  height: 16px;
}

.btn.btnPrimary,
.btn.btnPrimary:focus,
.btn.btnPrimary:active,
.btn.btnPrimary.btnActive {
  background: var(--primary-color);
}

.btn.btnPrimary:hover {
  outline: none;
  color: var(--btn-primary-hover-color);
  background: var(--btn-primary-hover-bg);
}

.btn:disabled,
.btn.btnPrimary:disabled,
.btn.btnSecondary:disabled,
.btn.btnSuccess:disabled {
  background-color: var(--secondary-color-nb-300) !important;
  color: var(--secondary-color-nb-500) !important;
  border: 1px solid var(--secondary-color-nb-300) !important;
  cursor: not-allowed;
}

.btn:disabled svg path,
.btn:disabled:hover svg path {
  fill: var(--secondary-color-nb-500) !important;
  stroke: var(--secondary-color-nb-500) !important;
}

.btn.btnSecondary,
.btn.btnSecondary:focus,
.btn.btnSecondary:active,
.btn.btnSecondary.btnActive {
  color: var(--btn-secondary-text-color);
  background: var(--btn-secondary-bg-color);
}

.btn.btnSecondary:hover {
  color: var(--btn-secondary-hover-color);
  background: var(--btn-secondary-hover-bg);
}

.btn.btnInfo,
.btn.btnInfo:focus,
.btn.btnInfo:active,
.btn.btnInfo.btnActive {
  color: var(--btn-info-text-color);
  background: var(--btn-info-bg-color);
}

.btn.btnInfo:hover {
  color: var(--btn-info-hover-text-color);
  background: var(--btn-info-hover-bg-color);
}

.btn.btnSuccess,
.btn.btnSuccess:focus,
.btn.btnSuccess:active,
.btn.btnSuccess.btnActive {
  background: var(--success-color);
}

.btn.btnSuccess:hover {
  color: var(--white-color);
  background: var(--btn-success-hover-bg);
}

.btn.btnWarning {
  background: var(--warning-color);
}

.btn.btnIcon.btnSmall {
  padding: 10px;
}

.btn.btnIcon.btnMiddle {
  padding: 14px;
}

.btn.btnIcon.btnLarge {
  padding: 18px;
}

.btn.btnSmall {
  padding: 10px 16px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.btn.btnMiddle {
  padding: 14px 16px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  max-height: 44px;
}

.btn.btnLarge {
  padding: 18px 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  max-height: 52px;
}

.btn:hover {
  outline: none;
  background: var(--btn-hover-bg);
}

.btn.btnGrey {
  color: var(--secondary-color-nb-300);
  background: var(--secondary-color-nb-600);
}

.btn.btnTransparent {
  color: var(--white-color);
  background: transparent;
}

.btn.btnTransparent:active,
.btn.btnTransparent.btnActive,
.btn.btnTransparent:hover {
  color: var(--white-50-color);
}

.btn.btnRound {
  border-radius: 100px;
}

.btn.btnOutlined {
  background-color: transparent;
  border: 1px solid var(--secondary-color-nb-600);
  color: var(--secondary-color-nb-300);
}

.btn.primaryNew {
  background-color: var(--secondary-color-tq-400);
  color: var(--secondary-color-nb-800);
  border: 1px solid var(--secondary-color-tq-400);
}

.btn.primaryNew:hover {
  background-color: var(--secondary-color-tq-600);
}

.btn.secondaryNew {
  background-color: var(--secondary-color-nb-800);
  color: var(--secondary-color-nb-200);
  border: 1px solid var(--secondary-color-nb-600);
}

.btn.secondaryNew:hover {
  background-color: var(--secondary-color-nb-1000);
}

.btn.tertiaryNew {
  background-color: var(--secondary-color-nb-1000);
  color: var(--secondary-color-nb-300);
  border: 1px solid var(--secondary-color-nb-600);
}

.btn.link {
  background-color: transparent;
  color: var(--secondary-color-nb-300);
  padding: 3px 0;
}

.btn.link:hover {
  color: var(--secondary-color-nb-100);
}

.btn.link :global(.ant-space-item) {
  text-decoration: underline;
  line-height: 22px;
  text-underline-offset: 3px;
}
